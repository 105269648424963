.notification-fixed {
  position: fixed;
  right: 0;
  z-index: 999;
}


.notification {
  $navbar-height: 56px; // this is arbitrary and can change based on navbar content
  $offset-top: $navbar-height + 20px;
  $offset-bottom: 20px;
  $offset-right: $navbar-padding-x;
  $offset-left: $navbar-padding-x;
  $padding-y: $alert-padding-y / 1.5;
  $padding-x: $alert-padding-x / 1.5;

  display: inline-block;
  margin: 0 auto;
  position: fixed;
  transition: all $animation-speed-default ease-in-out;
  z-index: $zindex-notifications;

  font-size: $font-size-sm;
  line-height: $line-height-sm;

  .alert {
    margin-bottom: $alert-margin-bottom / 2;
    padding: $padding-y $padding-x;

    &.alert-dismissible {
      padding-right: 4rem;

      button.close {
        padding: $padding-y $padding-x;
        line-height: 0.8;
      }
    }
  }

  // TOP & BOTTOM PLACING (left, center, right)
  &-tl,
  &-tc,
  &-tr {
    top: $offset-top;
  }

  &-bl,
  &-bc,
  &-br {
    bottom: $offset-bottom;
  }

  &-tl,
  &-bl {
    left: $offset-left;
  }

  &-tc,
  &-bc {
    left: 0;
    right: 0;
  }

  &-tr,
  &-br {
    right: $offset-right;
  }

  @include media-breakpoint-down(xs) {
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;

    .alert {
      border-radius: 0;
      border: 0;
      margin-bottom: 0;
    }
  }
}
