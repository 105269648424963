// Applies absolute sizing to fill parent container
@mixin sizing-fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sizing-fill {
  @include sizing-fill;
}

// Fill browser window
@mixin fill-window {
  min-height: 100vh;
}

.fill-window {
  @include fill-window;
}

.margin-vertical-center {
  margin-top: calc(100vh / 3);
}
