$grasp-blue: #49c0f7;
$grasp-violet: #537ae4;

//$gradient: linear-gradient(to right top, $grasp-blue, #34b0f6, #2f9ff3, #3e8dee, $grasp-violet);
$gradient: linear-gradient(225deg, $grasp-violet, $grasp-blue);

// override bootstrap colors
$primary: $grasp-violet;

$animation-speed-default: 0.25s;

// Should override all Bootstrap components
// https://getbootstrap.com/docs/4.1/layout/overview/#z-index
$zindex-notifications: 2000;

$alert-padding-y:                   .75rem !default;
$alert-padding-x:                   1.25rem !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;

$alert-margin-bottom:               1rem !default;

$padding-y: $alert-padding-y / 1.5;
$padding-x: $alert-padding-x / 1.5;

$navbar-padding-y:                  ($spacer / 2) !default;
$navbar-padding-x:                  $spacer !default;


