.bottom-bordered-input {
  border: 0;
  border-bottom: 1px solid black;
}

@media screen and (max-width: 400px) {
  .button-text {
    display: none;
  }
}

.grasp-blob-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.grasp-blob-button:focus {
  outline: none;
  box-shadow: none;
}
