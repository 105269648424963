.color-picker-popover-middle {
  left: -82px;
  top: 45px;
}

.color-picker-popover {
  position: absolute;
  z-index: 2;
}

.color-picker-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.color-picker-button {
  width: 40px;
  padding: 4px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer
}

.color-picker-button-inner-color {
  height: 30px;
  border: 1px solid #ced4da;
  border-radius: 3px;
}
