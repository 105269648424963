.organization-card {
  height: 160px;
  margin-top: $spacer;

  &--count {
    & > .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > .btn {
        text-align: left;
      }
    }
  }
}
